var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$vuetify.breakpoint.xs)?_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"to":{
        name: 'CourseRegister',
        params: { id: item.id, search: _vm.search },
      }}},[_c('ListIcon',[_c('v-icon',[_vm._v(_vm._s(_vm.subjectIcon(item.subject)))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.code))]),_c('v-list-item-subtitle',[_c('DateSpan',{attrs:{"small":"","value":[item.startDate, item.endDate]}})],1)],1),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-chip-group',_vm._l((item.groups),function(group){return _c('v-chip',{key:group,attrs:{"outlined":""}},[_vm._v(_vm._s(group))])}),1)],1),_c('v-chip',{attrs:{"small":"","outlined":""}},[_vm._v(_vm._s(item.teachers.map((el) => el.code).join(", ")))])],1)}),1):_c('v-data-table',{attrs:{"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":() => 'clickable'},on:{"click:row":(item) =>
        _vm.$router.push({
          name: 'CourseRegister',
          params: {
            id: item.id,
            search: _vm.search,
          },
        })},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('Icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.subjectIcon(item.subject)))]),_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.teachers",fn:function({ item }){return _vm._l((item.teachers),function(teacher){return _c('PersonItem',{key:teacher.id,attrs:{"small":"","value":teacher}})})}},{key:"item.date",fn:function({ item }){return [_c('DateSpan',{attrs:{"small":"","value":[item.startDate, item.endDate]}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }