<template>
  <div>
    <v-list v-if="$vuetify.breakpoint.xs">
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        :to="{
          name: 'CourseRegister',
          params: { id: item.id, search: search },
        }"
      >
        <ListIcon
          ><v-icon>{{ subjectIcon(item.subject) }}</v-icon></ListIcon
        >
        <v-list-item-content>
          <v-list-item-title>{{ item.code }}</v-list-item-title>
          <v-list-item-subtitle>
            <DateSpan small :value="[item.startDate, item.endDate]"> </DateSpan>
          </v-list-item-subtitle>
        </v-list-item-content>
        <div class="d-flex align-center justify-end">
          <v-chip-group>
            <v-chip v-for="group in item.groups" :key="group" outlined>{{
              group
            }}</v-chip>
          </v-chip-group>
        </div>
        <v-chip small outlined>{{
          item.teachers.map((el) => el.code).join(", ")
        }}</v-chip>
      </v-list-item>
    </v-list>
    <v-data-table
      v-else
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="items"
      :item-class="() => 'clickable'"
      @click:row="
        (item) =>
          $router.push({
            name: 'CourseRegister',
            params: {
              id: item.id,
              search: search,
            },
          })
      "
    >
      <template v-slot:item.title="{ item }">
        <Icon class="mr-4">{{ subjectIcon(item.subject) }}</Icon>
        {{ item.title }}
      </template>
      <template v-slot:item.teachers="{ item }">
        <PersonItem
          small
          v-for="teacher in item.teachers"
          :key="teacher.id"
          :value="teacher"
        >
        </PersonItem>
      </template>
      <template v-slot:item.date="{ item }">
        <DateSpan small :value="[item.startDate, item.endDate]"> </DateSpan>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DateSpan from "common/components/DateSpan.vue";
import PersonItem from "@/components/PersonItem.vue";

import { subjectIcon } from "common/utils/icons.js";

export default {
  name: "CourseList",
  components: { DateSpan, PersonItem },
  data() {
    return {
      headers: [
        {
          text: "Kurs",
          value: "title",
        },
        { text: "LK", value: "teachers" },
        {
          text: "Zeit",
          value: "date",
        },
        { text: "Gruppe(n)", value: "groups" },
        { text: "Anz.", value: "studentCount.total" },
      ],
    };
  },
  props: ["items", "search"],
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    student() {
      return this.personProfile;
    },
  },
  methods: { subjectIcon },
};
</script>
